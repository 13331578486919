import React from "react"
import Layout, { SimpleLayout } from "../components/layout"
import Hero from "../components/hero"
import Card from "../components/card"

import imageCPR from "../images/cards/card-cpr.png"
import imageFA from "../images/cards/card-fa.png"
import imageCustom from "../images/cards/card-custom.svg"

import { FooterWrapper } from "../components/footer"

import FAQ from "../components/faq"
import Quote from "../components/quote"
import DialogBox from "../components/dialogbox"
import CircleText from "../components/circletext"

//Benefits
import ThreeCircles from "../components/threecircles"
import imageFrequent from "../images/enterprise/frequent.svg"
import imageBoost from "../images/enterprise/boost.svg"
import imageReal from "../images/enterprise/real.svg"
//Additional
import imageAPI from "../images/healthcare/api.svg"
import imageVRDevice from "../images/healthcare/vrdevice.svg"
import imageSupport from "../images/healthcare/support.svg"
import imageTracking from "../images/healthcare/tracking.svg"
import imageCustomise from "../images/healthcare/customise.svg"
import imageAccount from "../images/healthcare/account.svg"

import imageBackground from "../images/enterprise/enterprise-background.png"

export default () => {
  let cardHeight = "550px"
  let additionalFeatures = [
    { image: imageAPI, text: "API integration <br> with LMS" },
    { image: imageVRDevice, text: "Compatible with various VR devices" },
    { image: imageSupport, text: "Software support <br> & updates" },
    { image: imageTracking, text: "Performance tracking with CSV export" },
    { image: imageCustomise, text: "Customisation <br> options" },
    { image: imageAccount, text: "Account management via back end" },
  ]
  return (
    <FooterWrapper>
      <Layout image={imageBackground}>
        <Hero
          center
          hero="VR for Enterprise"
          herosub="Browse through our product offering in the area of First Aid Training and beyond"
        />

        <div style={{ margin: "120px 0" }}>
          <div className="card-feed">
            <Card
              title="CPR Training"
              buttonTitle="FIND OUT MORE"
              cardImage={imageCPR}
              download={true}
              height={cardHeight}
            >
              <h2>Cardiopulmonary resuscitation</h2>
              <h3>For all staff</h3>
              <p>
                This course is created for all organisations that want their
                employees to learn how to save a life, in the case of cardiac
                arrest.
              </p>
            </Card>

            <Card
              title="First Aid Training"
              buttonTitle="FIND OUT MORE"
              cardImage={imageFA}
              download={true}
              height={cardHeight}
            >
              <h2>Emergency First Aid</h2>
              <h3>For all staff</h3>
              <p>
                This course is created for medium and large organisations that
                want their employees to gain essential First Aid skills,
                including CPR.
              </p>
            </Card>

            <Card
              buttonTitle="FIND OUT MORE"
              cardImage={imageCustom}
              enquire={true}
              height={cardHeight}
            >
              <h1>Custom Training</h1>
              <h2>Bespoke courses</h2>
              <h3>For all staff</h3>
              <p>
                We can provide various training modules in the Health & Safety
                or other areas, either as supplement to the existing training
                modules, or as a standalone product.
              </p>
            </Card>
          </div>
        </div>
      </Layout>

      <ThreeCircles
        title="Why VR training for Enterprise?"
        circle1={{
          image: imageFrequent,
          text: "Simulation-based training",
          subtext:
            "Experiencing a real-life scenario by VR simulation improves learning outcomes",
        }}
        circle2={{
          image: imageBoost,
          text: "Boosting confidence in abilities",
          subtext:
            "Employees don't refrain from acting in an emergency situation, they feel prepared",
        }}
        circle3={{
          image: imageReal,
          text: "Time-efficient training",
          subtext:
            "Immersive technology provides a way to reduce training time for employees",
        }}
      >
        <p>Key benefits of immersive training for Enterprise include:</p>
      </ThreeCircles>

      <CircleText data={additionalFeatures}>
        <h1>Additional features</h1>
        <p>
          All products have a user-centered approach with the aim of delivering
          engaging scenario training. Some training scenarios also incorporate
          ‘hands-on’ approaches by using external objects to deliver tactile
          feedback. Additional features of all courses are:
        </p>
      </CircleText>

      <Quote
        quote="Dual Good Health have been supportive
        through my entire process of onboarding.
        Always available to answer any questions that
        I had and assist with anything that they could
        help with. The course materials have met
        expectations and are going to be a great addition
        to my classes.
        In summary, the partnership has been an
        awesome experience and I wouldn't change
        anything! I would absolutely recommend
        Dual Good Health."
        quotee="Managing Director, Military veteran, US First Aid training company"
      />

      <DialogBox />

      <SimpleLayout style={{ background: "white", paddingBottom: "30px" }}>
        <h1 style={{ paddingTop: "60px", paddingBottom: "30px" }}>
          Frequently asked questions
        </h1>
        <FAQ
          question="Which VR devices is the software compatible with?"
          answer="All VR devices widely available on the market that are suitable for organisational use. This includes HTC Vive, Oculus Go, Oculus Rift and Oculus Quest (device to be released Q2 of 2019)."
        />
        <FAQ
          question="How many VR headsets do I need?"
          answer="It depends on the type of device and training setting. If your organisation has a VR room already and/or is lookig to train each employee individually, even just one HTC Vive device may be enough. If group training is your preferred option, we recommend at least 3 headsets for a group of 12 people, which can also be Oculus devices. "
        />
        <FAQ
          question="Do you provide VR hardware as part of the package?"
          answer="Normally we don’t. Depending on your location, however, we may be able to. Get in touch and we will do our best to help out. Note that, to all clients, we always provide advice on hardware purchasing options."
        />

        <FAQ
          question="Are software support and/or updates included?"
          answer="Absolutely! With all our packages you get constant software support by a friendly and responsive team. We also add new useful features to the software quite frequently, which are available at no additional cost. "
        />
        <FAQ
          question="Does the course curriculum follow official guidelines?"
          answer="Yes, it does. The curriculum is strictly following the corresponding guidelines by official bodies such as Resuscitation  Councils in Europe and American Heart Association in the US and internationally. "
        />
        <hr style={{ marginTop: "20px", marginBottom: "80px" }} />
        <h2 style={{ marginBottom: "30px", textAlign: "center" }}>
          Have more questions?
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <a href="mailto:hello@dualgoodhealth.com" className="button">
            CONTACT US
          </a>
        </div>
      </SimpleLayout>
    </FooterWrapper>
  )
}
